import axios from 'axios';

const API_Cours_USD = `${process.env.WALLET_URL}v4/rs/price/usd`;
const API_Cours_EUR = `${process.env.WALLET_URL}v4/rs/price/eur`;
const API_Wallets = `${process.env.WALLET_URL}v4/api/status/total`;

const today = new Date();
today.setDate(today.getDate());
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);
const formattedToday = today.toISOString().split('T')[0];
const formattedYesterday = yesterday.toISOString().split('T')[0];

const API_Turnover = `${process.env.BLOCKCHAIN_URL}api/stats/asset/${formattedYesterday}/${formattedToday}`;

const errorMassage = 'No data';

async function getRate() {
  try {
    const responseUSD = await axios.get(API_Cours_USD);
    const dataUSD = responseUSD.data;
    const priceUSD = Number(dataUSD.price).toFixed(2);
    if (priceUSD) {
      localStorage.setItem('priceUsd', priceUSD);
    }
  } catch (error) {
    console.log('Error fetching USD rate:', error);
  }

  try {
    const responseEUR = await axios.get(API_Cours_EUR);
    const dataEUR = responseEUR.data;
    const priceEUR = Number(dataEUR.price).toFixed(2);
    if (priceEUR) {
      localStorage.setItem('priceEur', priceEUR);
    }
  } catch (error) {
    console.log('Error fetching EUR rate:', error);
  }

  try {
    const responseWallets = await axios.get(API_Wallets);
    const dataWallets = responseWallets.data.total;
    const walletsCount = Number(dataWallets) + 3698;
    if (walletsCount) {
      localStorage.setItem('walletsCount', walletsCount);
    }
  } catch (error) {
    console.log('Error fetching wallets count:', error);
  }
  try {
    const response = await axios.get(API_Turnover);
    const data = response.data;

    const amountWithFee =
      data.stats.dailyStats.amountWithFee.find(item => item.denom === 'ovg')
        ?.amount ?? '0';

    const withdraw =
      data.stats.dailyStats.withdraw.find(item => item.denom === 'ovg')
        ?.amount ?? '0';

    const issued =
      data.stats.dailyStats.issued.find(item => item.denom === 'ovg')?.amount ??
      '0';
    const SATOSHI_AMOUNT = 100000000;
    const turnover = (
      (parseInt(amountWithFee) + parseInt(withdraw) + parseInt(issued)) /
      SATOSHI_AMOUNT
    ).toFixed(2);
    localStorage.setItem('turnover', turnover);
  } catch (error) {
    console.error('Error fetching data:', error);
  }

  const priceEurElement = document.getElementById('priceEur');
  priceEurElement.innerHTML = localStorage.getItem('priceEur') || errorMassage;

  const priceUsdElement = document.getElementById('priceUsd');
  priceUsdElement.innerHTML = localStorage.getItem('priceUsd') || errorMassage;

  const walletCountElement = document.getElementById('walletsCount');
  walletCountElement.innerHTML =
    localStorage.getItem('walletsCount') || errorMassage;

  const turnoverElement = document.getElementById('turnover');
  turnoverElement.innerHTML = localStorage.getItem('turnover') || errorMassage;
}

document.addEventListener('DOMContentLoaded', () => {
  getRate();
});
